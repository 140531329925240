import React, { useRef }  from 'react'

// Libraries
import { Row, Col, Container, Dropdown, Navbar } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { Link } from 'react-router-dom';
import { m, AnimatePresence } from "framer-motion";
import { Formik, Form } from 'formik';
import { ContactFormStyle02Schema } from '../../Components/Form/FormSchema';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import MessageBox from '../../Components/MessageBox/MessageBox';
import GoogleMap from '../../Components/GoogleMap/GoogleMap'



// Components
import Header, { HeaderCart, HeaderNav, Menu, SearchBar, Topbar } from '../../Components/Header/Header';
import FooterStyle08 from '../../Components/Footers/FooterStyle08'
import PortfolioBoxed from '../../Components/Portfolio/PortfolioBoxed'
import Buttons from '../../Components/Button/Buttons'
import IconWithText from '../../Components/IconWithText/IconWithText'
import { fadeIn } from '../../Functions/GlobalAnimations'
import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import SideButtons from "../../Components/SideButtons";
import Tab02 from '../../Components/Tab/Tab02'
import { fadeInLeft } from '../../Functions/GlobalAnimations'
import Blockquote from '../../Components/Blockquote/Blockquote'

// Data
import { portfolioBoxedData } from "../../Components/Portfolio/PortfolioData"
import HeaderData from "../../Components/Header/HeaderData";

const IconWithTextData = [
    {
        icon: "line-icon-Money-Bag text-[#828282] text-[40px]",
        title: "Effective strategy",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor.",
    },
    {
        icon: "line-icon-Gear-2 text-[40px] text-[#828282]",
        title: "Powerfull customize",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor.",
    },
    {
        icon: "line-icon-Talk-Man text-[40px] text-[#828282]",
        title: "Customer satisfaction",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor.",
    },
    {
        icon: "line-icon-Cursor-Click2 text-[40px] text-[#828282]",
        title: "No coding required",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor.",
    },
]

const swiperData = [
    {
        img: "/assets/img/dogan/slider/dogSlider012.png",
        title: "<h2 class='font-serif text-shadow-double-large lg:w-[70%] sm:w-full lg:mx-auto font-light text-white mb-16'>Global <span class='font-semibold'>agency making</span> a world of difference</h2>"
    },
    {
        img: "/assets/img/dogan/slider/dogSlider022.png",
        title: "<h2 class='font-serif text-shadow-double-large lg:w-[70%] sm:w-full lg:mx-auto font-light text-white mb-16'>Constantly thinking of <span class='font-semibold'>new and effective</span> ways</h2>"
    },
    {
        img: "/assets/img/dogan/slider/dogSlider032.png",
        title: "<h2 class='font-serif text-shadow-double-large lg:w-[70%] sm:w-full lg:mx-auto font-light text-white mb-16'>Superlative services with <span class='font-semibold'>highly technology</span></h2>"
    },
    {
        img: "/assets/img/dogan/slider/dogSlider042.png",
        title: "<h2 class='font-serif text-shadow-double-large lg:w-[70%] sm:w-full lg:mx-auto font-light text-white mb-16'>Superlative services with <span class='font-semibold'>highly technology</span></h2>"
    },
    {
        img: "/assets/img/dogan/slider/dogSlider052.png",
        title: "<h2 class='font-serif text-shadow-double-large lg:w-[70%] sm:w-full lg:mx-auto font-light text-white mb-16'>Superlative services with <span class='font-semibold'>highly technology</span></h2>"
    },
]

const TabData02 = [
    {
        tabTitle: 'Strafrecht',
        tabicons: "/assets/img/dogan/icons/strafrecht.png",
        subtitle: 'Voor al uw Strafzaken kunt u bij ons terecht. U kunt hierbij denken aan:',
        img: '/assets/img/dogan/tab/strafrecht.png',
        title: 'Wij zijn gespecialiseerd in het Strafrecht en kunnen u bijstaan in al uw strafzaken. ',
        content: '',
        list: 'True',
        // (diefstal, mishandeling, moord en doodslag, deelname aan criminele organisatie, fraudedelicten, zedendelicten, drugsdelicten, geweldsdelicten, mensenhandel, etc.)
        listItems: ["Het Algemene/Commune Strafrecht ", "Economisch Strafrecht", "Ontnemingszaken (Plukze-wetgeving)", "TBS zaken", "Verkeersdelicten", "etc."],
        buttonTitle: "Lees verder",
        buttonLink: "/rechtsgebieden/strafrecht"
    },
    {
        tabTitle: 'Jeugd Strafrecht',
        tabicons: '/assets/img/dogan/icons/jeugdStraf.png',
        img: '/assets/img/dogan/tab/jeugdStraf.png',
        title: 'Dankzij onze jarenlange ervaring in het jeugdstrafrecht kunnen wij u bijstaan in al uw jeugdstrafzaken.',
        subtitle: 'De gevolgen kunnen levensbepalend zijn. Wij staan u graag bij in deze moeilijke periode.',
        content: 'Deze tekst moet nog worden aangepast',
        buttonTitle: "Lees verder",
        buttonLink: "#"
    },
    {
        tabTitle: 'Civiel Recht',
        tabicons: 'assets/img/dogan/icons/civielrecht.png',
        img: '/assets/img/dogan/tab/civielRecht.png',
        title: 'Niets zo ingrijpend als een ondertoezichtstelling en/of de (gesloten) uithuisplaatsing.',
        subtitle: 'Wij begrijpen uw frustraties en zorgen en staan u graag bij in deze moeilijke periode.',
        content: 'Deze tekst moet nog worden aangepast',
        buttonTitle: "Lees verder",
        buttonLink: "#"
    },
    {
        tabTitle: 'WZD / BOPZ',
        tabicons: '/assets/img/dogan/icons/WZD.png',
        img: '/assets/img/dogan/tab/wzd_bopz.png',
        title: 'Wet zorg en dwang (WZD) en de Wet verplichte geestelijke gezondheidszorg (Wvggz)',
        subtitle: 'Wat betekent dit voor u? Wij staan u graag bij in deze moeilijke periode.',
        content: 'Deze tekst moet nog worden aangepast',
        buttonTitle: "Lees verder",
        buttonLink: "#"
    },
]

const SocialIconsData = [
    // {
    //     color: "#0038e3",
    //     link: "https://www.facebook.com/",
    //     icon: "fab fa-facebook-f"
    // },
    // {
    //     color: "#0038e3",
    //     link: "https://twitter.com/",
    //     icon: "fab fa-twitter"
    // },
    {
        color: "#0077b5",
        link: "https://www.linkedin.com/",
        icon: "fab fa-linkedin-in"
    },
    // {
    //     color: "#0038e3",
    //     link: "https://www.youtube.com/",
    //     icon: "fab fa-youtube"
    // }
]




const DoganHome = (props) => {
    const form1 = useRef(null)
    const recaptcha = useRef()
    const swiperRef = React.useRef(null)
    
    return (
        <div style={props.style}>

            {/* Header start */}
            <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
                {/* HeaderTopbar start */}
                <Topbar className="bg-lightgray border-b border-[#0000001a] sm:hidden md:px-[15px]">
                    <Container fluid="lg">
                        <Row>
                            <Col className="col-12 col-sm-auto text-center items-center flex text-sm-start me-auto">
                                <SocialIcons theme="social-icon-style-05" size="xs" iconColor="dark" data={SocialIconsData} />
                            </Col>
                            <Col className="col-auto flex xs:hidden text-right">
                                <div className="top-bar-contact flex">
                                    <span className="border-l border-[#0000001a] flex py-[13px] px-[18px] text-sm justify-center items-center">
                                        <i className="feather-phone-call mr-[8px] text-md relative text-[#DBAD23]"></i> +31 30 266 00 11 
                                    </span>
                                    <span className="border-l border-[#0000001a] py-[13px] px-[18px] text-sm flex justify-center items-center">
                                        <i className="far fa-envelope mr-[8px] text-md relative text-[#DBAD23]"></i>
                                        <a aria-label="mail" href="mailto:info@yadvocatenkantoordogan.nl" className="hover:text-darkgray">info@advocatenkantoordogan.nl</a>
                                    </span>
                                    <span className="border-l border-[#0000001a] py-[13px] px-[18px] flex md:pr-0 text-sm justify-center items-center sm:hidden">
                                        <i className="far fa-clock mr-[8px] text-md relative text-[#202020]"></i>
                                        Ma-Vr: 09:00 - 17:00
                                    </span>
                                </div>
                                {/* Start Flag dropdown INT */}
                                {/* <div className="header-language pl-0 text-[17px] align-middle inline-block md:hidden landscape:md:hidden">
                                    <Dropdown className="border-l border-r border-solid border-[#0000001a] h-full text-[#828282]">
                                        <Dropdown.Toggle className="rounded-none uppercase w-auto h-full font-normal text-xs leading-[11px] border-[0px] m-0 dropdown-toggle after:none sm-w-100 items-center flex px-[18px] py-[14px] bg-transparent" id="dropdown-basic">
                                            <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-0"><img height={16} width={16} src="/assets/img/country-flag-16X16/england.png" alt="" /></span>EN <span className="caret"></span> <i className="fas fa-angle-down top-[-1px] ml-[5px] align-middle relative after:content-[' ']"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-1">
                                                <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/usa.png" alt="" /></span>English
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-2">
                                                <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/england.png" alt="" /></span>England
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-3">
                                                <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/france.png" alt="" /></span>France
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-4">
                                                <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/russian.png" alt="" /></span>Russian
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start" href="#/action-5">
                                                <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]"><img height={16} width={16} src="/assets/img/country-flag-16X16/spain.png" alt="" /></span>Spain
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                                {/* End Flag dropdown INT */}
                            </Col>
                        </Row>
                    </Container>
                </Topbar>
                {/* HeaderTopbar end */}

                {/* HeaderNav Start */}
               <HeaderNav bg="white" theme="light" containerClass="!px-0" className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0">
          <Col lg={6} xs={"auto"} className="px-lg-0 position-absolute left-0 right-0 mx-lg-auto text-center md:!relative mr-auto">
            <Link aria-label="header logo" className="inline-block relative z-10" to="/">
              <Navbar.Brand className="inline-block p-0 m-0 align-middle">
                <img className="default-logo" width="111" height="36" loading="lazy" src='/assets/img/dogan/doganLogo.svg'  alt='logo' />
                <img className="alt-logo" width="111" height="36" loading="lazy" src='/assets/img/dogan/doganLogo.svg'  alt='logo' />
                <img className="mobile-logo" width="111" height="36" loading="lazy" src='/assets/img/dogan/doganLogo.svg'  alt='logo' />
              </Navbar.Brand>
            </Link>
          </Col>
          <Col className="md:flex md:justify-end md:px-[15px]">
            <Navbar.Toggle className="order-last md:ml-[17px]">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
            <div className="hidden md:block">
              <Navbar.Collapse className="col-auto justify-center">
                <Menu {...props} />
              </Navbar.Collapse>
            </div>
            <div className="flex justify-between md:hidden col-lg-9 col-xl-8 mx-auto !pl-[25px] !pr-[12px] lg:!pl-[15px] lg:!pr-0">
              <Menu data={HeaderData.slice(0, Math.floor(HeaderData.length / 2))} />
              <Menu data={HeaderData.slice(Math.floor(HeaderData.length / 2), (HeaderData.length - 0))} />
            </div>
          </Col>
        </HeaderNav>
                {/* HeaderNav End */}
            </Header>
            {/* Header End */}
            <SideButtons />
            {/* Slider Section Start */}
            <section className="p-0 overflow-hidden landscape:md:h-[600px] relative">
                <Swiper
                    ref={swiperRef}
                    // style={{ cursor: `url(/assets/img/webp/icon-move-light.webp)` }}
                    modules={[Autoplay, Keyboard, Pagination, Navigation]}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    loop={true}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                      }}                
                    slidesPerView={1}
                    className="white-move swiper-navigation-04">
                    {
                        swiperData.map((item, i) => {
                            return (
                                <SwiperSlide key={i} className="bg-no-repeat bg-cover relative overflow-hidden bg-center" style={{ backgroundImage: `url(${item.img})` }}>
                                    {/* <div className="absolute h-full w-full  opacity-[0.5] top-0 left-0 bg-darkgray"></div> */}
                                    <Container className="full-screen">
                                        {/* <Row className="justify-center h-full">
                                            <Col xl={8} className="relative flex flex-col justify-center text-center h-full px-[0px] xs:px-[15px] landscape:md:h-[600px]">
                                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                                <div className="font-medium flex justify-center font-serif uppercase xs:block">
                                                    <div className="xs:mb-[15px]">
                                                        <Buttons ariaLabel="our-services" to="/page/our-services" className="btn-fill btn-fancy font-medium tracking-[1px] rounded-none mr-[10px] xs:mr-0" themeColor="#fff" color="#232323" size="md" title="Explore services" />
                                                    </div>
                                                    <div>
                                                        <Buttons ariaLabel="home link" to="/" className="mx-[10px] font-medium btn-fancy tracking-[1px] rounded-none btn-transparent hover:text-black" themeColor="#fff" size="md" color="#fff" icon="fas fa-arrow-right right-icon" iconPosition="after" title="Discover litho" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </Container>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                {
                    <>
                        <div onClick={() => swiperRef.current.swiper.slidePrev()} className="absolute top-1/2 left-[40px] z-[1] -translate-y-1/2 cursor-pointer slider-navigation-style-07 swiper-button-previous-nav xs:left-[20px]">
                            <i className="line-icon-Arrow-OutLeft text-[40px] text-white flex justify-center items-center rounded-full h-[50px] w-[50px] text-center sm:h-[40px] sm:w-[40px] "></i>
                        </div>
                        <div onClick={() => swiperRef.current.swiper.slideNext()} className="absolute top-1/2 right-[40px] z-[1] -translate-y-1/2 cursor-pointer slider-navigation-style-07 swiper-button-next-nav  xs:right-[20px]">
                            <i className="line-icon-Arrow-OutRight text-[40px] text-white flex justify-center items-center rounded-full h-[50px] w-[50px] text-center sm:h-[40px] sm:w-[40px]"></i>
                        </div>
                    </>
                }
            </section>
            {/* Slider Section End */}

            <m.section id='kantoor' className="py-[50px] lg:py-[50px] md:py-[50px] sm:py-[50px] xs:py-[50px]"  {...fadeIn}>
                <Container>
                <Row className="justify-center">
                        <Col xl={12} lg={12} sm={12} className=" font-serif mb-3">
                            <span className="text-center block justify-center text-lg text-gradient bg-gradient-to-r from-[#353535] via-[#dbad23] via-[#dbad23] to-[#c49603] uppercase font-medium mb-[20px] sm:mb-[15px]">Advocatenkantoor Dogan</span>
                            <span className="text-darkgray text-lg text-align: right">Advocatenkantoor Dogan is een strafrechtkantoor gevestigd in de wijk Overvecht in Utrecht. Bij Advocatenkantoor Dogan kunt u terecht voor al uw strafzaken. Het kantoor wordt gekenmerkt door een actieve en daadkrachtige aanpak. Hierbij staan uw belangen voorop en krijgt uw strafzaak de volle aandacht.</span>
                        </Col>
                    </Row>
                    
                 </Container>
            </m.section>

            {/* Rechtsgebieden Section Start */}
            <m.section id='rechtsgebieden' className="py-[50px] bg-lightgray lg:py-[50px] md:py-[50px] sm:py-[50px] xs:py-[50px]"  {...fadeIn}>
                <Container>
                <Row className="justify-center">
                        <Col xl={5} lg={6} sm={8} className="text-center font-serif mb-3">
                            <span className="block justify-center text-lg text-gradient bg-gradient-to-r from-[#353535] via-[#dbad23] via-[#dbad23] to-[#c49603] uppercase font-medium mb-[20px] sm:mb-[15px]">Rechtsgebieden</span>
                            <h6 className="text-darkgray text-center font-semibold tracking-[-1px]">Wij verlenen rechtsbijstand op de volgende rechtsgebieden.</h6>
                        </Col>
                    </Row>
                    <Tab02 data={TabData02} />
                 </Container>
            </m.section>

            {/* Rechtsgebieden Section End */}    
          
            {/* Mr S. Dogan Section Start */}
      <m.section id='mrdogan' className="py-[50px] lg:py-[50px] md:py-[50px] sm:py-[50px] xs:py-[50px] overflow-hidden" {...fadeIn}>
        <Container>
        <Row className='mb-[6%] font-serif' >
            <Col>
            <span className="text-center block justify-center text-lg text-gradient bg-gradient-to-r from-[#353535] via-[#dbad23] via-[#dbad23] to-[#c49603] uppercase font-medium mb-[20px] sm:mb-[15px]">Mw. Mr. S. Dogan</span>
              <span className="font-serif text-darkgray text-lg text-align: right ">Mr. Dogan heeft Rechten gestudeerd aan de Universiteit van Utrecht en is in 2001 beëdigd als advocaat. Door haar passie voor het strafrecht is zij na haar studie aan de slag gegaan bij strafrechtadvocaat Jan Boone in Wijk bij Duurstede. Hier heeft zij van 2001 tot 2009 verschillende cliënten bijgestaan in omvangrijke en spraakmakende zaken. In 2009 heeft mr. Dogan haar eigen kantoor opgericht waarbij zij haar strafpraktijk succesvol voortzet. Mr. Dogan is actief in het hele land</span>
            </Col>
          </Row>
          <Row>
            <Col lg={11} xs={12}>
              <Row className="items-center">
                
                <m.div className="col-12 col-md-6 xs:mb-[50px]" {...fadeIn}>
                  <img height="" width="" src="/assets/img/dogan/uitsnedetek.png" alt="" />
                </m.div>
                <m.div className="col-12 col-md-6 col-xl-5 offset-xl-1" {...fadeInLeft}>
                  <Blockquote
                    className="xs:my-0"
                    theme="blockquote-style-02"
                    title="Mr. Dogan heeft Rechten gestudeerd aan de Universiteit van Utrecht en is in 2001 beëdigd als advocaat."
                    author="Mw. Mr. S. Dogan"
                    link="/mw-mr-s-dogan"
                  />
                </m.div>
              </Row>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Mr. S. Dogan Section End */}
                        
            {/* Contact Start */}
            <m.section id='contact' className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs;py-[50px] bg-lightgray" {...fadeIn}>
        <Container>
        <Row>
            <Col className="mb-[4%]">              
            <span className="text-center block justify-center text-lg text-gradient bg-gradient-to-r from-[#353535] via-[#dbad23] via-[#dbad23] to-[#c49603] uppercase font-medium mb-[20px] sm:mb-[15px]">Contact gegevens</span>
            </Col>
          </Row>
          <Row>
            <Col className="justify-center">
              <div className="row">
                <Col lg={6} className="lg:px-[15px] md:mb-[15px]">
                  <div className="bg-white drop-shadow-[0_0_15px_rgb(0,0,0,0.08)] p-[40px] sm:p-[30px]">
                  <GoogleMap
                className="w-full h-[400px]"
                location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2450.111760753558!2d5.12913917650841!3d52.11409527195762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66f2e58e3d22f%3A0x910dc0de41badca7!2sAdvocatenkantoor%20Dogan!5e0!3m2!1snl!2snl!4v1710277568127!5m2!1snl!2snl"
              />
                  </div>
                </Col>
                <Col lg={6} className="lg:px-[15px] ">
                <Formik
                initialValues={{ name: '', email: '', phone: '', comment: '', recaptcha: '' }}
                validationSchema={ContactFormStyle02Schema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  if (values.recaptcha !== '') {
                    const response = await sendEmail(values)
                    response.status === "success" && resetForm(actions, recaptcha);
                  } else {
                    recaptcha.current.captcha.classList.add("error")
                  }
                }}
              >
                {({ isSubmitting, status, setFieldValue }) => (
                  <Form ref={form1}>
                    <Input showErrorMsg={false} name="name" type="text" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Uw naam" labelClass="mb-[25px]" />
                    <Input showErrorMsg={false} name="email" type="email" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="UW email adres" labelClass="mb-[25px]" />
                    <Input showErrorMsg={false} name="phone" type="tel" className="py-[18px] px-[25px] w-full border-[1px] border-solid border-[#dfdfdf]" placeholder="Uw mobiele number" labelClass="mb-[25px]" />
                    <TextArea
                      className="mb-[35px] pt-[22px] px-[25px] pb-[32px] w-full border-[1px] border-solid border-[#dfdfdf] resize-none"
                      name="comment"
                      rows="5"
                      placeholder="Hoe kunnen we u helpen?"></TextArea>
                    {process.env.REACT_APP_GRECAPTCHA_API_KEY && (
                      <ReCAPTCHA
                        ref={recaptcha}
                        className="mb-[35px]"
                        sitekey={process.env.REACT_APP_GRECAPTCHA_API_KEY}
                        onChange={(response) => { setFieldValue("recaptcha", response) }}
                      />
                    )}
                    <Buttons ariaLabel="form button" type="submit" className={`font-medium font-serif rounded-none uppercase text-[11px]${isSubmitting ? " loading" : ""}`} themeColor={["#353535", "#dbad23"]} size="md" color="#fff" title="Verstuur bericht" />
                    <AnimatePresence>
                      {status && <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><MessageBox className="mt-[35px] py-[10px] tracking-[1px]" theme="message-box01" variant="success" message="Your message has been sent successfully!" /></m.div>}
                    </AnimatePresence>
                  </Form>
                )}
              </Formik>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
            {/* Contact End */}

            {/* Footer Start */}
            <FooterStyle08 theme="dark" className="text-slateblue bg-[#262b35]" />
            {/* Footer End */}
        </div>
    )
}

export default DoganHome
