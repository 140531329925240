import React, { memo } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PropTypes } from "prop-types";
import { fadeIn } from '../../Functions/GlobalAnimations'

// Components
import SocialIcons from '../SocialIcon/SocialIcons'
import FooterMenu, { Footer } from './Footer'

// Data
import FooterData from './FooterData'

const SocialIconsData05 = [
    // {
    //     color: "#00aced",
    //     link: "https://twitter.com/",
    //     icon: "fab fa-twitter"
    // },
    {
        color: "#0077b5",
        link: "https://www.linkedin.com/",
        icon: "fab fa-linkedin-in"
    },
    
]

// const iconData = [
//     {
//         color: "#828282",
//         link: "https://nl.linkedin.com/in/sevda-dogan-29129131",
//         icon: "fab fa-linkedin"
//     },
//     // {
//     //     color: "#828282",
//     //     link: "https://dribbble.com/",
//     //     icon: "fab fa-dribbble"
//     // },
//     // {
//     //     color: "#828282",
//     //     link: "https://twitter.com/",
//     //     icon: "fab fa-twitter"
//     // },
//     // {
//     //     color: "#828282",
//     //     link: "https://www.instagram.com/",
//     //     icon: "fab fa-instagram"
//     // },

// ]

const FooterStyle08 = (props) => {
    return (
        <Footer className={`${props.className ? ` ${props.className}` : ""}`} theme={props.theme}>
            <div className="py-[5%] lg:py-[8%] sm:py-[50px]">
                <Container>
                    <Row className="justify-between md:justify-start">
                        <Col lg={{ span: 3, order: 0 }} md={5} sm={{ span: 6, order: 1 }} className="md:mb-[50px] xs:mb-[25px]">
                            <Link aria-label="link" to="/" className="mb-[30px] block">
                                <img src={props.logo} alt="logo" width="111" height="36" />
                            </Link>
                            <p className="w-[85%] mb-[15px]">Wolgadreef 102-104, 3561 CS Utrecht, Nederland</p>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+31 30 266 00 11</div>
                            <div><i className="feather-mail text-sm mr-[10px] text-themecolor"></i><a aria-label="mail" href="mailTo:info@advocatenkantoordogan.nl">info@advocatenkantoordogan.nl</a></div>
                            <SocialIcons theme="social-icon-style-05" className="justify-start text-center mt-[15px]" iconColor="light" data={SocialIconsData05} animation={fadeIn} />
                            <p className="my-[15px]"><img width={179} height={57} src="/assets/img/dogan/hightrust.jpg" alt='' /></p>
                        </Col>
                        <FooterMenu data={FooterData.slice(0, 2)} lg={{ span: "auto"}} md={{ span: 3 }} sm={6} xs={{order:2}} className="md:mb-[40px] xs:mb-[25px]" titleClass="capitalize" />
                        <Col lg={{ span: "auto", offset: 0 }} md={{ span: "auto", offset: 2 }} sm={{ order: 3 }} xs={{span:12,order:3}} className="lg:text-start">
                            <span className="mb-[30px] font-medium text-themecolor font-serif block sm:hidden">Aangesloten bij:</span>
                            <div className="flex flex-col sm:flex-row xs:text-center xs:block justify-between">
                                
                                <Link aria-label="link" className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]" to="https://www.advocatenorde.nl" target="_blank" rel="noopener noreferrer"><img width={179} height={57} src="/assets/img/dogan/nederlandse-orde-van-advocaten-logo.svg" alt='' /></Link>
                                <Link aria-label="link" className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]" to="https://www.rechtsbijstand.nl" target="_blank" rel="noopener noreferrer"><img width={179} height={57} src="/assets/img/dogan/raad-voor-rechtsbijstand.svg" alt='' /></Link>
                                <Link aria-label="link" className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]" to="https://www.nvsa.nl" target="_blank" rel="noopener noreferrer"><img width={179} height={57} src="/assets/img/dogan/nvsa.svg" alt='' /></Link>
                                <Link aria-label="link" className="inline-block mb-[15px] sm:mb-0 xs:mb-[15px]" to="https://www.vnja.nl" target="_blank" rel="noopener noreferrer"><img width={179} height={57} src="/assets/img/dogan/vnja.png" alt='' /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="p-[35px] border-t border-[#ffffff1a] xs:py-[35px] xs:px-0">
                <Container>
                    <Row>
                        <Col md={9} className="md:mb-[0.75rem] xs:mb-[15px] xs:items-center sm:mb-[15px]">
                            <ul className="flex md:justify-start sm:justify-center xs:text-center xs:flex-col">
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px] xs:mr-0"><Link aria-label="link" to="/page/enquete">Cliënt Tevredenheidsonderzoek</Link></li>
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px] xs:mr-0"><Link aria-label="link" to="/page/disclaimer">Disclaimer</Link></li>
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px] xs:mr-0"><Link aria-label="link" to="/page/tarieven">Privacy Verklaring</Link></li>
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px] xs:mr-0"><Link aria-label="link" to="/page/alg-voorwaarden">Alg. Voorwaarden</Link></li>
                                <li className="mr-[35px] md:mr-[20px] xl:mb-0 xs:mb-[7px] xs:mr-0"><Link aria-label="link" to="/page/klachten">Klachten Regeling</Link></li> 
                            </ul>
                        </Col>
                                {/* <span className="justify-end flex sm:justify-center text-align:end md:text-end">&copy; {new Date().getFullYear()} Advocatenkantoor Dogan </span> */}
                        <Col md={3} className="justify-end flex sm:justify-center text-center md:text-end">
                            <>&copy; {new Date().getFullYear()} Advocatenkantoor Dogan </>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle08.defaultProps = {
    data: FooterData,
    className: "bg-darkgray text-[#828282]",
    logo: "/assets/img/dogan/footerLogoDogan.png"
}

FooterStyle08.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,

}

export default memo(FooterStyle08)