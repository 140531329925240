const HeaderData = [
    {
        title: 'Het Kantoor',
        // link: '/'
        zoef: 'kantoor',
        
    },
    {
        title: 'Rechtsgebieden',
        zoef: 'rechtsgebieden',
        dropdown: [
            {
                title: 'Strafrecht',
                link: '/rechtsgebieden/strafrecht'
            },
            {
                title: 'Jeugd Strafrecht',
                link: '/rechtsgebieden/jeugd-strafrecht'
                
            },
            {
                title: 'Civiel Recht',
                link: '/rechtsgebieden/civiel-recht'
            },
            {
                title: 'Wet Zorg en Dwang (Wzd) / BOPZ – Psychiatrisch Patiëntenrecht',
                link: '/rechtsgebieden/wzd-bopz'
                // dropdown: [
                //     {
                //         title: 'Contact simple',
                //         link: '/page/contact-simple'
                //     },
                //     {
                //         title: 'Contact classic',
                //         link: '/page/contact-classic'
                //     },
                //     {
                //         title: 'Contact modern',
                //         link: '/page/contact-modern'
                //     }
                // ]
            },
        ]
    },
    
    {
        title: 'Mw. Mr. S. Dogan',
        // link: '/mw-mr-s-dogan',
        zoef: 'mrdogan',
        dropdown: [
            {
                title: 'Over Mw. Mr. S. Dogan',
                link: '/over-mw-mr-s-dogan'
            },
            {
                title: 'Werkwijze',
                link: '/werkwijze'
            },
            {
                title: 'Tarieven',
                link: '/tarieven'
            },
            {
                title: 'Contact',
                link: '/contact'
            },
        ]
    },
    {
        title: 'Contact',
        zoef: 'contact',
        
    },
]

export default HeaderData