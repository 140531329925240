const FooterData = [
    {
        title: "Advocatenkantoor Dogan",
        submenu: [
            {
                title: "Home",
                link: "/"
            },
            {
                title: "Rechtsgebieden",
                link: "#"
            },
            {
                title: "Mw. mr. S. Dogan",
                link: "/mw-mr-s-dogan"
            },
            {
                title: "Contact",
                link: "/contact"
            },
            {
                title: "Vacatures",
                link: "/vacatures"
            },
            {
                title: "Tarieven",
                link: "/tarieven"
            },
        ]
    },
    {
        title: "links",
        submenu: [
            {
                title: "Rechtspraak",
                link: "https://www.rechtspraak.nl/",
                target: "_blank",
                rel: "noopener noreferrer"
            },
            {
                title: "Jeugdzorg Nederland",
                link: "https://www.jeugdzorgnederland.nl/",
                target: "_blank",
                rel: "noopener noreferrer"
            },
            {
                title: "Kinderbescherming",
                link: "https://www.kinderbescherming.nl/",
                target: "_blank",
                rel: "noopener noreferrer"
            },
            {
                title: "Dwang in de zorg (Wvggz/Wzd)",
                link: "https://www.dwangindezorg.nl/",
                target: "_blank",
                rel: "noopener noreferrer"
            },
            {
                title: "Dienst Justitiële Inrichtingen",
                link: "https://www.dji.nl/",
                target: "_blank",
                rel: "noopener noreferrer"
            },
        ]
    },
    {
        title: "Additional",
        submenu: [
            {
                title: "Our story",
                link: "/page/our-story"
            },
            {
                title: "Who we are",
                link: "/page/who-we-are"
            },
            {
                title: "Our process",
                link: "/page/our-process"
            },
            {
                title: "Latest news",
                link: "/page/latest-news"
            },
        ]
    },
    {
        title: "Services",
        submenu: [
            {

                title: "Brand experience",
                link: "/page/our-services"
            },
            {
                title: "e-Commerce website",
                link: "/page/our-services"
            },
            {
                title: "Content writing",
                link: "/page/our-services"
            },
            {
                title: "Marketing strategy",
                link: "/page/our-services"
            },
        ]
    },
    {
        title: "Resources",
        submenu: [
            {
                title: "Theme guide",
                link: "/page/faq-s"
            },
            {
                title: "Support desk",
                link: "/page/faq-s"
            },
            {
                title: "What we offer",
                link: "/page/what-we-offer"
            },
            {
                title: "Company history",
                link: "/page/our-story"
            },
        ]
    },
    {
        title: "Categories",
        submenu: [
            {
                title: "For men",
                link: "/shop/shop-wide"
            },
            {
                title: "For woman",
                link: "/shop/shop-wide"
            },
            {
                title: "Accessories",
                link: "/shop/shop-wide"
            },
            {
                title: "Collections",
                link: "/shop/shop-wide"
            },
        ]
    },
    {
        title: "Adavantages",
        submenu: [
            {
                title: "Free delivery",
                link: "/page/faq-s"
            },
            {
                title: "100 days refund",
                link: "/page/faq-s"
            },
            {
                title: "Multiple payments",
                link: "/page/faq-s"
            },
            {
                title: "Sustainable",
                link: "/page/faq-s"
            },
        ]
    },
]

export default FooterData